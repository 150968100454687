<template>
    <div class="price-range-component">
        <div class="d-sm-flex flex-sm-column white-background rounded-lg">
            <div class="pa-4">
                <p class="ma-0 pb-2 body-heading font-weight-semi-bold" align="left">{{ $t("Customer.VerifyAppointment.Price")}} (KS)</p>

                <div class="rounded-lg light-grey-border">
                    <div class="px-4 py-2 d-sm-flex justify-sm-space-between">
                        <div class="pl-md-2 d-sm-flex flex-sm-column justify-sm-center align-sm-center">
                            <p class="ma-0 body-heading font-weight-medium">{{ $t("Customer.priceRangeComponent.Min")}}</p>
                            <div class="pa-1 rounded price-box">
                                <p class="ma-0 body-text font-weight-medium blue-text"><b>{{ priceRange[0] }}</b></p>
                            </div>
                        </div>

                        <div class="pl-md-2 d-sm-flex flex-sm-column justify-sm-center align-sm-center">
                            <p class="ma-0 body-heading font-weight-medium">{{ $t("Customer.priceRangeComponent.Max")}}</p>
                            <div class="pa-1 rounded price-box">
                                <p class="ma-0 body-text font-weight-medium blue-text"><b>{{ priceRange[1] }}</b></p>
                            </div>
                        </div>
                    </div>

                    <div class="pa-sm-2 price-range-slider">
                        <v-range-slider
                            v-model="priceRange"
                            :min="minPrice"
                            :max="maxPrice"
                            :step="step"
                            height="20"
                            thumb-color="white"
                            @change="onPriceRangeChange"
                            class="ma-0"
                        >
                        </v-range-slider>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    props: {
    priceRange: {
        type: Array,
        required: true
    }},
    data() {
        return {
            minPrice: 0,
            maxPrice: 150000,
            step: 1000,
            // priceRange: [0, 150000]
        };
    },
    
    methods: {
        onPriceRangeChange() {
            this.$emit("getProductsOnPriceFilter", this.priceRange[0], this.priceRange[1]);
        }
    }
}
</script>

<style lang="scss" scoped>
.price-box {
    border: 1px solid #E0E0E0;
    width: 64px;
}
</style>

<style lang="scss">
.price-range-slider .v-range-slider__track {
  background-color: #1467BF; // customize the color of the track
//   height: 10px; // set the height of the track to match the height of the thumbs
}

.price-range-slider .v-slider--horizontal .v-slider__track-container {
    height: 5px !important;
}

.price-range-slider .v-slider__thumb:before {
    // width: 80px;
    // height: 80px;
    // border-radius: 40px;
    color: transparent !important;
}

.price-range-slider .v-slider__thumb:after {
    // width: 80px;
    // height: 80px;
    // border-radius: 40px;
    color: transparent !important;
}

.price-range-slider .v-slider__thumb {
    border: 1px solid #1467BF;
}

.price-range-slider .v-slider--horizontal {
    margin: 0px;
}

.white-background {
    background-color: white;
}

.blue-text {
    color: #1467BF;
}

h2 {
    @media (max-width: 600px) {
        font-size: 16px;
        font-weight: 600;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		font-size: 16px;
        font-weight: 600;
	}

	@media (min-width: 1265px) {
		font-size: 20px;
        font-weight: 600;
	}
}

h3 {
    @media (max-width: 600px) {
        font-size: 14px;
        font-weight: 600;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		font-size: 14px;
        font-weight: 600;
	}

	@media (min-width: 1265px) {
		font-size: 16px;
        font-weight: 600;
	}
}

h4 {
    @media (max-width: 600px) {
        font-size: 12px;
        font-weight: 500;
	}

	@media (min-width: 601px) and (max-width: 1264px) {
		font-size: 12px;
        font-weight: 500;
	}

	@media (min-width: 1265px) {
		font-size: 14px;
        font-weight: 500;
	}
}
</style>